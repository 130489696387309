<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card class="pa-4">
          <v-layout wrap justify-start>
            <v-flex align-self-center xs12 sm4 md4 pr-0 pr-sm-1 pt-4>
              <span class="title2">Reservation.No</span>

              <v-text-field
                dense
                v-model="reservationNumber"
                outlined
                disabled
                type="number"
                hide-spin-buttons
                hide-details="auto"
              >
              </v-text-field>
            </v-flex>
            <!-- <v-flex align-self-center xs12 sm4 md4 pr-0 pr-sm-1 pt-4>
              <span class="title2">Status</span>
          
              <v-select
              hide-details="auto"
              v-model="reservationStatus"
               :items="['Confirm', 'Not Confirm']"
              :menu-props="{ maxHeight: '400' }"
              outlined
              persistent-hint
              dense
            ></v-select>
            </v-flex> -->
            <v-flex align-self-center xs12 sm4 md4 pr-0 pr-sm-1 pt-4>
              <span class="title2">Date Of Blocking</span>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="blockingDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="blockingDate"
                    background-color="white"
                    outlined
                    color="#182444"
                    readonly
                    disabled
                    hide-details
                    clearable
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="blockingDate"
                  no-title
                  :min="nowDate"
                  scrollable
                  @change="$refs.menu1.save(blockingDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <!-- <v-flex align-self-start text-right xs12 sm4 md4 pr-0 pr-sm-1>
              <v-btn
                outlined
                @click="addHotel = true"
                class="btnstly mt-2"
                style="cursor: pointer"
                color="#ff6200"
                ><span style="font-family: LexendFont; text-transform: none"
                  >Add Bank Details</span
                ></v-btn
              >
            </v-flex> -->
            <v-flex align-self-start xs12 sm6 pr-0 pr-sm-1 pt-4>
              <v-layout wrap justify-center>
                <v-flex align-self-center xs12 text-left>
                  <span class="title2">Email</span>
                  <v-btn icon @click="dialog = true">
                    <v-icon color="black">mdi-plus</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 align-self-center text-left>
                  <v-card
                    outlined
                    class="pa-1 mr-0 mr-sm-2"
                    v-if="mails.length"
                  >
                    <span
                      v-for="(email, index) in mails"
                      :key="index"
                      class="ma-1"
                    >
                      {{ email }} </span
                    ><br />
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex align-self-start xs12 sm6 pr-0 pr-sm-1 pt-4>
              <v-layout wrap justify-center>
                <v-flex align-self-center xs12 text-left>
                  <span class="title2">Phones</span>
                  <v-btn icon @click="phoneDialog = true">
                    <v-icon color="black">mdi-plus</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 align-self-center text-left>
                  <v-card
                    outlined
                    class="pa-1 mr-0 mr-sm-2"
                    v-if="phones.length"
                  >
                    <span
                      v-for="(phone, index) in phones"
                      :key="index"
                      class="ma-1"
                    >
                      {{ phone }} </span
                    ><br />
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex align-self-start xs12 sm6 md4 pr-0 pr-sm-1 pt-4>
              <span class="title2">Guest Name:</span>
              <v-text-field
                dense
                outlined
                type="text"
                v-model="guestName"
                hide-details="auto"
              >
              </v-text-field>
            </v-flex>
            <v-flex align-self-start xs12 sm6 md4 pr-0 pr-sm-1 pt-4>
              <v-layout wrap>
                <v-flex xs12 sm5>
                  <span class="title2">Booking Mode</span></v-flex
                >
                <v-flex xs12 sm7>
                  <v-select
                    hide-details="auto"
                    v-model="bookingMode"
                    :items="['Direct', 'Company', 'Welkin', 'Agent', 'Online']"
                    :menu-props="{ maxHeight: '400' }"
                    outlined
                    persistent-hint
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm5 pt-2>
                  <span class="title2">Company Name</span></v-flex
                >
                <v-flex xs12 sm7 pt-2>
                  <v-text-field
                    dense
                    v-model="companyName"
                    outlined
                    type="text"
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm5 pt-2>
                  <span class="title2">Address</span></v-flex
                >
                <v-flex xs12 sm7 pt-2>
                  <v-text-field
                    dense
                    v-model="companyAddress"
                    outlined
                    type="text"
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              align-self-start
              xs12
              sm6
              md4
              pl-0
              pl-sm-4
              pr-0
              pr-sm-1
              pt-2
            >
              <span class="title2">GST No.</span>
              <v-text-field
                dense
                v-model="companyGST"
                outlined
                type="text"
                hide-details="auto"
              />
              <!-- <v-btn
                outlined
                block
                @click="add()"
                class="btnstly mt-2"
                style="cursor: pointer"
                color="#ff6200"
                ><span style="font-family: LexendFont; text-transform: none"
                  >Create Booking Mode</span
                ></v-btn
              > -->
            </v-flex>
            <!-- <v-flex align-self-start xs12 sm6 md4 pt-4 pr-0 pr-sm-2>
              <span class="title2">State</span>
            
              <v-text-field
                dense
                v-model="state"
                outlined
                type="text"
                @change="checkgstcal()"
                hide-details="auto"
              />
            </v-flex> -->
            <v-flex align-self-start xs12 sm6 md4 pt-4 pr-0 pr-sm-2>
              <span class="title2">Country</span>
              <v-text-field
                dense
                v-model="country"
                outlined
                type="text"
                hide-details="auto"
              />
            </v-flex>
            <v-flex align-self-start xs12 sm6 md4 pt-4>
              <span class="title2">Payment Mode</span>
              <v-select
                hide-details="auto"
                v-model="paymentMode"
                :items="[
                  'Agent',
                  'Cash',
                  'Online',
                  'Directly',
                  'Cheque',
                  'Credit',
                ]"
                :menu-props="{ maxHeight: '400' }"
                outlined
                persistent-hint
                dense
              ></v-select>
            </v-flex>
            <v-flex xs12 py-2><v-divider></v-divider></v-flex>
            <v-flex xs12 align-self-center>
              <v-simple-table class="outlined-table">
                <thead>
                  <tr>
                    <th>
                      <!-- <v-card width="90px" elevation="0" color="transparent"><span style="color: #626270"> -->
                      Room Type
                      <!-- </span></v-card> -->
                    </th>
                    <th style="width: 17%">
                      <v-card width="110px" elevation="0" color="transparent"
                        ><span style="color: #626270">Arrival</span></v-card
                      >
                    </th>
                    <th style="width: 120px !important">
                      <v-card width="110px" elevation="0" color="transparent"
                        ><span style="color: #626270">Check Out</span></v-card
                      >
                    </th>
                    <th>
                      Total <br />
                      Rooms
                    </th>
                    <th>Night</th>
                    <th>Meal Plan</th>
                    <th>Total Adult/ Room</th>
                    <th>Room Amt / Night</th>

                    <th>Extra Adult With Bed</th>
                    <th>Rate</th>
                    <th>Extra Adult Without Bed</th>
                    <th>Rate</th>
                    <th>Child With Matters</th>
                    <th>Rate</th>
                    <th>Child Without Matters</th>
                    <th>Rate</th>
                    <th>Total Pax</th>
                    <!-- <th>Cut Off Date</th> -->
                    <th>Extra Bed Total Amt.</th>
                    <th>Total Amt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-select
                        hide-details="auto"
                        v-model="currentRow.type"
                        :items="RoomCatList"
                        :menu-props="{ maxHeight: '400' }"
                        item-text="roomsDisplayName"
                        item-value="_id"
                        outlined
                        persistent-hint
                        dense
                      ></v-select>
                    </td>
                    <td>
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :return-value.sync="currentRow.checkInDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="currentRow.checkInDate"
                            color="#182444"
                            readonly
                            hide-details
                            clearable
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="currentRow.checkInDate"
                          no-title
                          :min="nowDate"
                          scrollable
                          @change="$refs.menu3.save(currentRow.checkInDate)"
                        >
                          <v-spacer></v-spacer>
                        </v-date-picker>
                      </v-menu>
                    </td>
                    <td>
                      <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        :return-value.sync="currentRow.checkOutDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="currentRow.checkOutDate"
                            color="#182444"
                            readonly
                            hide-details
                            clearable
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="currentRow.checkOutDate"
                          no-title
                          :min="nowDate"
                          scrollable
                          @change="$refs.menu4.save(currentRow.checkOutDate)"
                        >
                          <v-spacer></v-spacer>
                        </v-date-picker>
                      </v-menu>
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.numberOfRooms"
                        dense
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.nights"
                        dense
                        disabled
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-select
                        hide-details="auto"
                        v-model="currentRow.plan"
                        :items="['CP', 'MAP', 'AP', 'EP']"
                        :menu-props="{ maxHeight: '400' }"
                        outlined
                        persistent-hint
                        dense
                      ></v-select>
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.totalAdult"
                        dense
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.amtPerNight"
                        dense
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.extraAdultWithBed"
                        dense
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.extraAdultWithBedRate"
                        dense
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.totalAdultWithOutBed"
                        dense
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.totalAdultRateWithOutBed"
                        dense
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.totalChildWithBed"
                        dense
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.totalChildRateWithBed"
                        dense
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.totalChildWithOutBed"
                        dense
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.totalChildRateWithOutBed"
                        dense
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>

                    <td>
                      <v-text-field
                        v-model="currentRow.totalPax"
                        dense
                        disabled
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="currentRow.extraBedTotalAmt"
                        dense
                        disabled
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>

                    <td>
                      <v-text-field
                        v-model="currentRow.totalAmt"
                        dense
                        disabled
                        hide-details="auto"
                        type="number"
                        hide-spin-buttons
                      />
                    </td>
                    <td>
                      <v-btn small text :disabled="!isRowComplete">
                        <v-icon @click="addRow">mdi-plus</v-icon></v-btn
                      >
                    </td>
                  </tr>
                  <tr v-for="(row, index) in rows" :key="index">
                    <td>{{ getCategoryNameById(row.type) }}</td>
                    <td>{{ row.checkInDate }}</td>
                    <td>{{ row.checkOutDate }}</td>
                    <td>{{ row.numberOfRooms }}</td>
                    <td>{{ row.nights }}</td>
                    <td>{{ row.plan }}</td>
                    <td>{{ row.totalAdult }}</td>
                    <td>{{ row.amtPerNight }}</td>

                    <td>{{ row.extraAdultWithBed }}</td>
                    <td>{{ row.extraAdultWithBedRate }}</td>
                    <td>{{ row.totalAdultWithOutBed }}</td>
                    <td>{{ row.totalAdultRateWithOutBed }}</td>
                    <td>{{ row.totalChildWithBed }}</td>
                    <td>{{ row.totalChildRateWithBed }}</td>
                    <td>{{ row.totalChildWithOutBed }}</td>
                    <td>{{ row.totalChildRateWithOutBed }}</td>
                    <td>{{ row.totalPax }}</td>
                    <!-- <td>{{ row.cutOffDate }}</td> -->
                    <td>{{ row.extraBedTotalAmt }}</td>
                    <td>{{ row.totalAmt }}</td>
                    <!-- <td>{{ calculateTotalRow(row) }}</td> -->
                    <td>
                      <v-icon @click="deleteRow(index)" color="red"
                        >mdi-delete</v-icon
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-flex>
            <v-flex xs12 md11 align-self-center v-if="availabilityData">
              <!-- <v-layout wrap justify-start v-if="availabilityData.length > 0">
          
                <v-flex xs12 text-left pt-4
                  ><span class="title2">Room Availability</span></v-flex
                >
                <v-flex xs12 text-left v-if="isAvailable === true"
                  ><span class="title3" style="color: red"
                    >Total rooms exceeds room availability</span
                  ></v-flex
                >
                <v-flex
                  xs12
                  sm2
                  align-self-center
                  py-2
                  v-for="(item, i) in availabilityData"
                  :key="i"
                >
                  <v-card width="140px" outlined class="mx-0 mx-sm-1">
                    <v-card height="80px" width="100%" pa-2 elevation="0">
                      <v-layout wrap justify-center fill-height>
                        <v-flex
                          xs12
                          align-self-center
                          text-center
                          style="line-height: 17px"
                        >
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 400;
                              font-size: 10px;
                              text-transform: uppercase;
                            "
                            >{{ getMonth(item.date) }}</span
                          ><br />
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 400;
                              font-size: 20px;
                            "
                            >{{ getDayOfMonth(item.date) }}</span
                          ><br />
                        
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 400;
                              font-size: 10px;
                              text-transform: uppercase;
                            "
                            >Rooms : {{ item.rooms }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-card>
                </v-flex>
              </v-layout> -->
              <v-layout wrap justify-start v-if="currentRow.numberOfRooms">
                <v-flex
                  xs12
                  sm6
                  md3
                  v-for="(detail, index) in availabilityData"
                  :key="index"
                >
                  <v-card elevation="0" class="pa-2">
                    <v-layout wrap justify-center>
                      <v-flex xs12 py-2>
                        {{ formatDate(detail.date) }}
                      </v-flex>
                      <v-flex
                        xs12
                        v-for="room in detail.roomNumbers"
                        :key="room._id"
                        align-self-center
                      >
                        <v-checkbox
                          :label="'Room ' + room.roomNumber"
                          :value="room._id"
                          hide-details="auto"
                          :input-value="
                            selectedRooms[detail.date] &&
                            selectedRooms[detail.date].includes(room._id)
                          "
                          @change="
                            updateSelectedRooms(detail.date, room._id, $event)
                          "
                        />
                      </v-flex>
                    </v-layout>
                    <!-- </v-card-text> -->
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 align-self-center text-left pt-4 v-if="rows">
              <v-radio-group
                dense
                v-if="rows.length > 0"
                v-model="billingMode"
                row
                class="mt-0 pt-0"
                hide-details="auto"
              >
                <v-radio
                  color="orange"
                  label="Including GST"
                  value="includeGST"
                ></v-radio>
                <v-radio
                  color="orange"
                  label="Excluding GST"
                  value="excludeGST"
                ></v-radio>
                <v-radio
                  color="orange"
                  label="Without GST"
                  value="withoutGST"
                ></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs12 sm6 md3 align-self-center text-left>
              <v-layout wrap justify-start>
                <v-flex xs2 align-self-center text-left pt-4>
                  <span class="title2">GST</span>
                </v-flex>
                <v-flex xs6 align-self-center text-left pt-4>
                  <v-text-field
                    v-model="gstRate"
                    outlined
                    dense
                    disabled
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm6 md3 align-self-center text-left>
              <v-layout wrap justify-start>
                <v-flex xs2 align-self-center text-left pt-4>
                  <span class="title2">SGST</span>
                </v-flex>
                <v-flex xs6 align-self-center text-left pt-4>
                  <v-text-field
                    v-model="sgstRate"
                    outlined
                    dense
                    disabled
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm6 md3 align-self-center text-left>
              <v-layout wrap justify-start>
                <v-flex xs2 align-self-center text-left pt-4>
                  <span class="title2">CGST</span>
                </v-flex>
                <v-flex xs6 align-self-center text-left pt-4>
                  <v-text-field
                    v-model="cgstRate"
                    outlined
                    dense
                    disabled
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm6 md3 align-self-center text-left>
              <v-layout wrap justify-start>
                <v-flex xs2 align-self-center text-left pt-4>
                  <span class="title2">IGST</span>
                </v-flex>
                <v-flex xs6 align-self-center text-left pt-4>
                  <v-text-field
                    v-model="igstRate"
                    outlined
                    dense
                    disabled
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 py-4 align-self-center><v-divider></v-divider></v-flex>
            <v-flex xs12 sm5>
              <v-layout wrap v-if="state && billingMode && totalPayableAmt">
                <v-flex xs6 pt-2>
                  <span class="title2">Hotel GST</span>
                </v-flex>
                <v-flex xs6 pt-2>
                  <v-text-field
                    v-model="hotelGst"
                    dense
                    disabled
                    outlined
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex>
                <v-flex xs6 pt-2>
                  <span class="title2">Base Price</span>
                </v-flex>
                <v-flex xs6 pt-2>
                  <v-text-field
                    v-model="basePrice"
                    dense
                    disabled
                    outlined
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex>
                <v-flex xs6 pt-2>
                  <span class="title2">Total Payable amount</span>
                </v-flex>
                <v-flex xs6 pt-2>
                  <v-text-field
                    v-model="finalAmt"
                    dense
                    disabled
                    outlined
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex>
                <!-- <v-flex xs6 pt-2>
                  <span class="title2">Advance Payment</span>
                </v-flex>
                <v-flex xs6 pt-2>
                  <v-text-field
                    v-model="advancePayment"
                    dense
                    outlined
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex>
                <v-flex xs6 pt-2>
                  <span class="title2">Balance Amt</span>
                </v-flex>
                <v-flex xs6 pt-2>
                  <v-text-field
                    v-model="balanceAmt"
                    dense
                    outlined
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex>
                <v-flex xs6 pt-2>
                  <span class="title2">Balance Payed By</span>
                </v-flex>
                <v-flex xs6 pt-2>
                  <v-text-field
                    v-model="balancePayedBy"
                    dense
                    outlined
                    hide-details="auto"
                    type="text"
                  />
                </v-flex> -->
              </v-layout>
            </v-flex>
            <v-spacer class="hide-sm-and-down"></v-spacer>
            <v-flex xs12 sm5>
              <v-layout wrap justify-end fill-height>
                <!-- <v-flex xs6 pt-2>
                  <span class="title2">Total Payable amount</span>
                </v-flex>
                <v-flex xs6 pt-2>
                  <v-text-field
                    v-model="totalPayableAmt"
                    dense
                    disabled
                    outlined
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex> -->
                <v-flex xs6 sm4 pt-2>
                  <span class="title2">Cut Off Date</span>
                </v-flex>
                <v-flex xs6 pt-2>
                  <v-menu
                    ref="menu5"
                    v-model="menu5"
                    :close-on-content-click="false"
                    :return-value.sync="cutOffDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="cutOffDate"
                        color="#182444"
                        readonly
                        hide-details
                        clearable
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="cutOffDate"
                      no-title
                      :min="nowDate"
                      scrollable
                      @change="$refs.menu5.save(cutOffDate)"
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <!-- <v-flex xs4 pt-2>
                  <span class="title2">Room Available</span>
                </v-flex>
                <v-flex xs6 pt-2>
                  <v-text-field
                    v-model="totalStayValue"
                    dense
                    outlined
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex>
                <v-flex xs4 pt-2>
                  <span class="title2">Current Room Available Balance</span>
                </v-flex>
                <v-flex xs6 pt-2>
                  <v-text-field
                    v-model="advanceReceived"
                    dense
                    outlined
                    hide-details="auto"
                    type="number"
                    hide-spin-buttons
                  />
                </v-flex> -->
                <v-flex xs6 pt-2 align-self-end> </v-flex>
                <v-flex xs6 pt-2 align-self-end>
                  <v-btn
                    color="#f17343"
                    dark
                    block
                    @click="validation()"
                    class="btnstly"
                    style="cursor: pointer"
                    ><span style="font-family: LexendFont; text-transform: none"
                      >Save</span
                    ></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
        <!-- Dialog for Adding Emails -->
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Add Emails</span>
            </v-card-title>
            <v-card-text>
              <v-flex xs12 sm10 pt-4 text-left>
                <span class="title2">Mail ID</span>
                <v-layout wrap>
                  <v-flex xs11>
                    <v-text-field
                      dense
                      v-model="newMail"
                      outlined
                      type="email"
                      hide-details="auto"
                      :rules="[rules.email]"
                    />
                  </v-flex>
                  <v-flex xs1 align-self-center>
                    <v-icon @click="addMailField" :disabled="!newMail">
                      mdi-plus
                    </v-icon>
                  </v-flex>
                </v-layout>
                <v-layout wrap v-if="mails.length > 0">
                  <v-flex xs12 pt-4 v-for="(mail, index) in mails" :key="index">
                    <v-layout wrap>
                      <v-flex xs11>
                        <v-text-field
                          dense
                          hide-details
                          v-model="mails[index]"
                          outlined
                          type="email"
                          hide-spin-buttons
                          disabled
                        />
                      </v-flex>
                      <v-flex xs1 align-self-center>
                        <v-icon @click="deleteMail(index)"> mdi-delete </v-icon>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialog = false">Save</v-btn>
              <v-btn text @click="dialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="phoneDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Add Phone Numbers</span>
            </v-card-title>
            <v-card-text>
              <v-flex xs12 sm10 pt-4 text-left>
                <span class="title2">Phone Number</span>
                <v-layout wrap>
                  <v-flex xs11>
                    <v-text-field
                      dense
                      v-model="newPhone"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                      :rules="[rules.phone]"
                    />
                  </v-flex>
                  <v-flex xs1 align-self-center>
                    <v-icon @click="addPhoneField" :disabled="!newPhone">
                      mdi-plus
                    </v-icon>
                  </v-flex>
                </v-layout>
                <v-layout wrap v-if="phones.length > 0">
                  <v-flex
                    xs12
                    pt-4
                    v-for="(phone, index) in phones"
                    :key="index"
                  >
                    <v-layout wrap>
                      <v-flex xs11>
                        <v-text-field
                          dense
                          hide-details
                          v-model="phones[index]"
                          outlined
                          type="tel"
                          hide-spin-buttons
                          disabled
                        />
                      </v-flex>
                      <v-flex xs1 align-self-center>
                        <v-icon @click="deletePhone(index)">
                          mdi-delete
                        </v-icon>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="phoneDialog = false">Save</v-btn>
              <v-btn text @click="phoneDialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="addHotel" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">Add Bank Details & Policies</span>
            </v-card-title>
            <v-card-text>
              <v-layout wrap>
                <!-- Bank Details -->
                <v-flex xs12 sm6 align-self-start pr-0 pr-md-1>
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="title2">Bank Name</span>
                      <v-text-field
                        dense
                        v-model="bankDetails.bankName"
                        outlined
                        hide-details="auto"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <span class="title2">Branch Name</span>
                      <v-text-field
                        dense
                        v-model="bankDetails.branchName"
                        outlined
                        hide-details="auto"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <span class="title2">IFSC Code</span>
                      <v-text-field
                        dense
                        v-model="bankDetails.ifscCode"
                        outlined
                        hide-details="auto"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <span class="title2">Account Name</span>
                      <v-text-field
                        dense
                        v-model="bankDetails.accountName"
                        outlined
                        hide-details="auto"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <span class="title2">Account Number</span>
                      <v-text-field
                        dense
                        v-model="bankDetails.accountNumber"
                        outlined
                        hide-details="auto"
                        type="number"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>

                <!-- Policy Details -->
                <v-flex xs12 sm6 align-self-start pl-0 pl-md-1>
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="title2">Booking Policy</span>
                      <v-textarea
                        dense
                        v-model="bankDetails.bookingPolicy"
                        outlined
                        hide-details="auto"
                        rows="4"
                        placeholder="Enter booking policy"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <span class="title2">Cancellation Policy</span>
                      <v-textarea
                        dense
                        v-model="bankDetails.cancellationPolicy"
                        outlined
                        hide-details="auto"
                        rows="4"
                        placeholder="Enter cancellation policy"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="saveDetails">Save</v-btn>
              <v-btn text @click="addHotel = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex xs12></v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      dialog: false,
      menu3: false,
      menu4: false,
      menu5: false,
      RoomCatList: [],
      newMail: "",
      mails: [],
      phoneDialog: false,
      newPhone: "",
      phones: [],
      //
      menu1: false,
      nowDate: new Date().toISOString().slice(0, 10),
      reservationNumber: "",
      reservationStatus: "",
      blockingDate: new Date().toISOString().slice(0, 10),
      guestName: "",
      availabilityData: [],
      reservationNo: "",
      bookingMode: "",
      companyName: "",
      companyAddress: "",
      companyGST: "",
      country: "",
      paymentMode: "",
      billingMode: "",
      gstRate: "",
      sgstRate: "",
      cgstRate: "",
      igstRate: "",
      totalPayableAmt: 0,
      advancePayment: "",
      balanceAmt: 0,
      balancePayedBy: "",
      cutOffDate: "",
      totalStayValue: "",
      advanceReceived: "",
      currentRow: {
        roomId: "",
        type: "",
        checkInDate: "",
        checkOutDate: "",
        numberOfRooms: 0,
        nights: 0,
        plan: "",
        totalAdult: 0,
        extraAdultWithBed: 0,
        extraAdultWithBedRate: 0,
        totalAdultWithOutBed: 0,
        totalAdultRateWithOutBed: 0,
        totalChildWithBed: 0,
        totalChildRateWithBed: 0,
        totalChildWithOutBed: 0,
        totalChildRateWithOutBed: 0,
        totalPax: 0,
        // cutOffDate: "",
        extraBedTotalAmt: 0,
        amtPerNight: 0,
        totalAmt: 0,
      },
      addHotel: false,
      bankDetails: {
        bankName: "",
        branchName: "",
        ifscCode: "",
        accountName: "",
        accountNumber: "",
        bookingPolicy: "",
        cancellationPolicy: "",
      },
      rows: [],
      rules: {
        phone: [
          (v) => !!v || "Phone number is required",
          (v) => /^\d+$/.test(v) || "Phone number must be valid",
        ],
        email: [
          (v) => !!v || "Email is required",
          (v) => /.+@.+/.test(v) || "Email must be valid",
        ],
      },

      //calender
      curdate: new Date().toISOString().split("T")[0],
      enddate: null,
      weekArray: [],
      newArr: [],
      isAvailable: false,
      selectedRooms: [],
      details: [],
      checkDateList: [],
      //gst calculations
      gstPercentage: 0,
      totalPayableAftergst: 0,
      state: "",
      hotelGst: 0,
      finalAmt: 0,
      basePrice: 0,
    };
  },
  computed: {
    isRowComplete() {
      // console.log("Current Row Data:", this.currentRow); // Log all currentRow data
      return (
        this.currentRow.type &&
        this.currentRow.checkInDate &&
        this.currentRow.checkOutDate &&
        this.currentRow.numberOfRooms &&
        this.currentRow.nights &&
        this.currentRow.plan &&
        this.currentRow.totalAdult &&
        this.currentRow.totalPax &&
        this.currentRow.amtPerNight
      );
    },
  },
  mounted() {
    this.getReservationNo();
    this.getRoomCat();
  },
  watch: {
    "currentRow.type": function () {
      this.checkAvailability();
    },
    "currentRow.checkInDate": function () {
      this.checkAvailability();
      if (this.currentRow.checkOutDate) {
        this.calculateNights();
      }
    },
    "currentRow.checkOutDate": function () {
      this.checkAvailability();
      if (this.currentRow.checkInDate) {
        this.calculateNights();
      }
    },
    "currentRow.numberOfRooms"(newValue) {
      // Call checkRoomsExceeded with the new value
      this.checkRoomsExceeded(newValue);
    },
    currentRow: {
      handler() {
        // Calculate total for the current row
        this.currentRow.rowTotal = this.calculateTotalRow(this.currentRow);
      },
      deep: true,
    },
    "currentRow.totalAdult": function () {
      this.calculateTotalPax();
    },
    "currentRow.extraAdultWithBed": function () {
      this.calculateTotalPax();
    },
    "currentRow.totalAdultWithOutBed": function () {
      this.calculateTotalPax();
    },
    "currentRow.totalChildWithBed": function () {
      this.calculateTotalPax();
    },
    "currentRow.totalChildWithOutBed": function () {
      this.calculateTotalPax();
    },
    "currentRow.extraAdultWithBedRate": function () {
      this.calculateExtraBedTotalAmt();
    },
    "currentRow.totalAdultRateWithOutBed": function () {
      this.calculateExtraBedTotalAmt();
    },
    "currentRow.totalChildRateWithBed": function () {
      this.calculateExtraBedTotalAmt();
    },
    "currentRow.totalChildRateWithOutBed": function () {
      this.calculateExtraBedTotalAmt();
    },
    billingMode() {
      // Call the function to handle billingMode changes
      this.handleBillingMode();
    },

    totalPayableAmt() {
      if (this.state) {
        this.calculateGST();
      } else {
        console.log("state not choosed");
      }
    },
  },

  methods: {
    formatDate(date) {
      // Parse the date string, which is in IST
      const localDate = new Date(date);

      // Use toLocaleDateString with a specific locale (en-IN for India)
      return localDate.toLocaleDateString("en-IN", {
        timeZone: "Asia/Kolkata", // Ensure it uses IST
      });
    },

    getCategoryNameById(id) {
      const category = this.RoomCatList.find((item) => item._id === id);
      return category ? category.roomsDisplayName : "";
    },
    checkRoomsExceeded(value) {
      let exceeded = false; // Track if the rooms exceed available rooms

      this.availabilityData.forEach((item) => {
        console.log(
          `Comparing: Entered value = ${value}, Available rooms = ${item.rooms}`
        );

        if (value > item.rooms) {
          console.log(
            `Exceeded: Entered value = ${value} exceeds Available rooms = ${item.rooms}`
          );
          exceeded = true; // Set flag to true if any comparison shows an exceedance
        }
      });

      if (exceeded) {
        console.log("The entered number exceeds the available rooms.");
      } else {
        console.log("The entered number is within the available rooms.");
      }

      this.isAvailable = exceeded; // Set the result of whether rooms exceeded
      return this.isAvailable;
    },
    getDatesInRange(startDate, endDate) {
      const dates = [];
      let currentDate = new Date(startDate);
      const lastDate = new Date(endDate);

      while (currentDate <= lastDate) {
        dates.push(currentDate.toISOString().split("T")[0]); // Get the date part in ISO format
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }

      return dates;
    },
    calculateTotalDays(checkInDate, checkOutDate) {
      const checkIn = new Date(checkInDate);
      const checkOut = new Date(checkOutDate);

      // Calculate the difference in time (in milliseconds)
      const timeDifference = checkOut.getTime() - checkIn.getTime();

      // Convert time difference to days
      const totalDays = Math.floor(timeDifference / (1000 * 3600 * 24));

      // Return totalDays + 1 to include the last day (e.g., 20, 21, 22 = 3 days)
      return totalDays;
    },

    addRow() {
      console.log("add - currentRow=", this.currentRow);
      // Step 1: Calculate total number of days
      const totalDays = this.calculateTotalDays(
        this.currentRow.checkInDate,
        this.currentRow.checkOutDate
      );
      let proceed = false;
      // Step 2: Check if selectedRooms length matches the total number of days
      if (this.selectedRooms.length === totalDays) {
        console.log(
          "Selected rooms match the total number of days:",
          totalDays
        );
        // check if all dates no of row===no of room/night
        let room_length = this.selectedRooms.map((x) => x.rooms.length);
        console.log(room_length);

        let extra_room = room_length.filter(
          (x) => x != this.currentRow.numberOfRooms
        );
        console.log(extra_room);

        if (extra_room.length > 0) {
          console.log("more rooms selected");
          this.msg = "Incorrect no.of rooms";
          this.showSnackBar = true;
          proceed = false;
        } else {
          console.log("correct rooms selected");
          proceed = true;
        }
      } else {
        console.error("Selected rooms do not match the total number of days.");
        this.msg = "Please select rooms for all the selected days";
        this.showSnackBar = true;
        proceed = false;
        // Handle the case where the number of selected rooms is incorrect
      }

      if (proceed === true) {
        // Check if the row is complete
        if (!this.isRowComplete) {
          console.log(
            "Row is incomplete. Ensure all required fields are correct."
          );
          this.msg =
            "Row is incomplete. Ensure all required fields are correct";
          this.showSnackBar = true;
          return;
        }

        // Ensure room addition and total calculation
        this.roomAdition();
      }
    },

    roomAdition() {
      console.log("Room selection validated. Proceeding with row addition...");

      // Calculate the total amount for the current row
      this.currentRow.totalAmt = this.calculateTotalRow(this.currentRow);

      // Add selectedRooms to the currentRow
      this.currentRow.selectedRooms = [...this.selectedRooms];

      // Push a deep copy of currentRow to rows array to avoid reactivity issues
      this.rows.push({ ...this.currentRow });

      console.log("Updated rows=", this.rows);

      // Recalculate the total payable amount
      this.updateTotalPayableAmt();

      // Reset the current row after successfully adding it
      this.resetCurrentRow();
    },
    deleteRow(index) {
      this.rows.splice(index, 1);
      // Recalculate the total payable amount after deletion
      this.updateTotalPayableAmt();
    },

    updateTotalPayableAmt() {
      // Calculate the totalPayableAmt using the totalAmt of each row
      this.totalPayableAmt = this.rows.reduce((sum, row) => {
        console.log("Row totalAmt:", row.totalAmt);
        return sum + row.totalAmt;
      }, 0);
      console.log("this.totalPayableAmt=", this.totalPayableAmt);
    },

    calculateTotalRow(row) {
      // Update total amount based on your row calculation
      return (
        row.nights *
        (row.numberOfRooms * row.amtPerNight +
          row.extraAdultWithBed * row.extraAdultWithBedRate +
          row.totalAdultWithOutBed * row.totalAdultRateWithOutBed +
          row.totalChildWithBed * row.totalChildRateWithBed +
          row.totalChildWithOutBed * row.totalChildRateWithOutBed)
      );
    },
    //
    checkAvailability() {
      // Check if all three fields have values
      if (
        this.currentRow.type &&
        this.currentRow.checkInDate &&
        this.currentRow.checkOutDate
      ) {
        this.getRoomAvailability();
      }
    },
    resetCurrentRow() {
      this.currentRow = {
        type: "",
        checkInDate: "",
        checkOutDate: "",
        numberOfRooms: 0,
        nights: 0,
        plan: "",
        totalAdult: 0,
        extraAdultWithBed: 0,
        extraAdultWithBedRate: 0,
        totalAdultWithOutBed: 0,
        totalAdultRateWithOutBed: 0,
        totalChildWithBed: 0,
        totalChildRateWithBed: 0,
        totalChildWithOutBed: 0,
        totalChildRateWithOutBed: 0,
        totalPax: 0,
        // cutOffDate: 0,
        extraBedTotalAmt: 0,
        amtPerNight: 0,
        totalAmt: 0,
        selectedRooms: [],
      };
      this.selectedRooms = [];
      this.availabilityData = [];
    },
    normalizeDate(date) {
      // Create a new Date object
      const localDate = new Date(date);

      // Extract the year, month, and day from the local date (not UTC)
      const year = localDate.getFullYear();
      const month = (localDate.getMonth() + 1).toString().padStart(2, "0"); // Ensure 2 digits
      const day = localDate.getDate().toString().padStart(2, "0"); // Ensure 2 digits

      // Return in 'YYYY-MM-DD' format
      return `${year}-${month}-${day}`;
    },
    updateSelectedRooms(date, roomId, isChecked) {
      // Normalize the date to ensure we are comparing and storing correct day
      const normalizedDate = this.normalizeDate(date);

      // Find the object for the given normalized date
      let selectedDate = this.selectedRooms.find(
        (item) => item.date === normalizedDate
      );

      // If there's no entry for the date, initialize one
      if (!selectedDate) {
        selectedDate = { date: normalizedDate, rooms: [] };
        this.selectedRooms.push(selectedDate);
      }

      // Add or remove room based on checkbox status
      if (isChecked) {
        // Add room if not already present
        if (!selectedDate.rooms.includes(roomId)) {
          selectedDate.rooms.push(roomId);
        }
      } else {
        // Remove room if it exists in the array
        selectedDate.rooms = selectedDate.rooms.filter((id) => id !== roomId);
      }

      // If there are no more selected rooms for the date, remove the object entirely
      if (selectedDate.rooms.length === 0) {
        this.selectedRooms = this.selectedRooms.filter(
          (item) => item.date !== normalizedDate
        );
      }

      console.log("Updated Selected Rooms:", this.selectedRooms);
    },
    validation() {
      // if (!this.companyName) {
      //   this.msg = "Please enter company name";
      //   this.showSnackBar = true;
      //   return;
      // } else
      if (!this.blockingDate) {
        this.msg = "Please enter blocking date";
        this.showSnackBar = true;
        return;
      }
      // else if (!this.guestName) {
      //   this.msg = "Please enter guest name";
      //   this.showSnackBar = true;
      //   return;
      // }
      else if (!this.bookingMode) {
        this.msg = "Please choose booking mode";
        this.showSnackBar = true;
        return;
      } else if (!this.paymentMode) {
        this.msg = "Please choose payment mode";
        this.showSnackBar = true;
        return;
      } else if (!this.billingMode) {
        this.msg = "Please choose GST type";
        this.showSnackBar = true;
        return;
      } else if (!this.cutOffDate) {
        this.msg = "Please choose cut-off date";
        this.showSnackBar = true;
        return;
      }
      // Check if rows array is empty
      else if (this.rows.length === 0) {
        this.msg = "Please add at least one row";
        this.showSnackBar = true;
        return;
      }
      // Check if any field in bankDetails is empty
      // else if (
      //   !this.bankDetails.bankName ||
      //   !this.bankDetails.branchName ||
      //   !this.bankDetails.ifscCode ||
      //   !this.bankDetails.accountName ||
      //   !this.bankDetails.accountNumber ||
      //   !this.bankDetails.bookingPolicy ||
      //   !this.bankDetails.cancellationPolicy
      // ) {
      //   this.msg = "Please fill out all bank details fields";
      //   this.showSnackBar = true;
      //   return;
      // }
      else {
        this.add();
      }
    },

    add() {
      this.appLoading = true;
      const companyInfo = {
        companyGST: this.companyGST,
        companyAddress: this.companyAddress,
        companyName: this.companyName,
        country: this.country,
      };
      // const selectedRoomsArray = Object.keys(this.selectedRooms).map(
      //   (date) => ({
      //     date: date, // The date
      //     rooms: [...new Set(this.selectedRooms[date])], // Remove duplicates using Set
      //   })
      // );
      axios({
        method: "POST",
        url: "/hotel/reservation/add",
        data: {
          bankDetails: this.bankDetails,
          reservationNumber: this.reservationNumber,
          // reservationStatus: this.reservationStatus,
          blockingDate: this.blockingDate,
          guestName: this.guestName,
          bookingMode: this.bookingMode,
          companyInfo: companyInfo,
          phone: this.phones,
          email: this.mails,
          paymentMode: this.paymentMode,
          billingMode: this.billingMode,
          GstAmount: this.gstRate,
          sgstAmount: this.sgstRate,
          cgstAmount: this.cgstRate,
          igstAmount: this.igstRate,
          totalPayableAmount: this.finalAmt,
          // advanceAmount: this.advancePayment,
          // balanceAmount: this.balanceAmt,
          // balancePaidby: this.balancePayedBy,
          cuttOffDate: this.cutOffDate,
          baseRate: this.basePrice,
          // hotelChainName: this.advanceReceived,
          bookingItem: this.rows,
          hotelId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          //          window.location.reload();
          window.location.reload();
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    // getRoomCat() {
    //   this.appLoading = true;
    //   axios
    //     .get("/room/category/getlist", {
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //       params: {
    //         hotelId: this.$route.query.id,
    //       },
    //     })
    //     .then((response) => {
    //       this.appLoading = false;
    //       this.RoomCatList = response.data.data;
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    getRoomCat() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/get/room/names",
        data: {
          hotelId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.RoomCatList = response.data.data;
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    //calender
    getRoomAvailability() {
      this.availabilityData = [];
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/get/room/availability",
        data: {
          hotelId: this.$route.query.id,
          roomId: this.currentRow.type,
          fromDate: this.currentRow.checkInDate,
          toDate: this.currentRow.checkOutDate,
          bookingItem: this.rows,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.availabilityData = response.data.details;
          this.currentRow.roomId = response.data.roomId;
          // this.currentRow.roomId = response.data.roomId;
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    // Get day of the week (e.g., "Monday")
    getDay(dateString) {
      const date = new Date(dateString);
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[date.getDay()];
    },

    // Get the day of the month (e.g., 10)
    getDayOfMonth(dateString) {
      const date = new Date(dateString);
      return date.getDate();
    },

    // Get the month (e.g., "September")
    getMonth(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString("default", { month: "long" });
    },
    calculateNights() {
      const checkInDate = new Date(this.currentRow.checkInDate);
      const checkOutDate = new Date(this.currentRow.checkOutDate);

      if (checkInDate && checkOutDate && checkOutDate > checkInDate) {
        const diffTime = checkOutDate - checkInDate;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Difference in days
        this.currentRow.nights = diffDays;
      } else {
        this.currentRow.nights = 0;
      }
    },
    calculateTotalPax() {
      const totalAdult = Number(this.currentRow.totalAdult);
      const extraAdultWithBed = Number(this.currentRow.extraAdultWithBed);
      const totalAdultWithOutBed = Number(this.currentRow.totalAdultWithOutBed);
      const totalChildWithBed = Number(this.currentRow.totalChildWithBed);
      const totalChildWithOutBed = Number(this.currentRow.totalChildWithOutBed);
      this.currentRow.totalPax = 0;
      // Calculate totalPax
      this.currentRow.totalPax =
        totalAdult +
        extraAdultWithBed +
        totalAdultWithOutBed +
        totalChildWithBed +
        totalChildWithOutBed;

      // Log the final calculated totalPax
      console.log("Total Pax: ", this.currentRow.totalPax);
    },
    calculateExtraBedTotalAmt() {
      // Convert to Number and check if the values are present, if not, default to 0
      const extraAdultWithBedRate =
        Number(this.currentRow.extraAdultWithBedRate) || 0;
      const totalAdultRateWithOutBed =
        Number(this.currentRow.totalAdultRateWithOutBed) || 0;
      const totalChildRateWithBed =
        Number(this.currentRow.totalChildRateWithBed) || 0;
      const totalChildRateWithOutBed =
        Number(this.currentRow.totalChildRateWithOutBed) || 0;

      // Calculate the sum of all rates
      this.currentRow.extraBedTotalAmt =
        extraAdultWithBedRate +
        totalAdultRateWithOutBed +
        totalChildRateWithBed +
        totalChildRateWithOutBed;

      // Log the final calculated extraBedTotalAmt
      console.log("Extra Bed Total Amt: ", this.currentRow.extraBedTotalAmt);
    },

    addMailField() {
      if (this.newMail) {
        this.mails.push(this.newMail);
        this.newMail = "";
      }
    },
    deleteMail(index) {
      this.mails.splice(index, 1);
    },
    addPhoneField() {
      if (this.newPhone) {
        this.phones.push(this.newPhone);
        this.newPhone = "";
      }
    },
    deletePhone(index) {
      this.phones.splice(index, 1);
    },
    saveDetails() {
      // Save the details or perform any action needed with the bankDetails object
      this.addHotel = false; // Close the dialog after saving
    },
    getReservationNo() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/get/reseravtion/number",
        params: {
          hotelId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.reservationNumber = response.data.reservationNumber;
          this.gstPercentage = response.data.gstPercentage;
          this.state = response.data.hotelData.state;
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    //gst calculations
    checkgstcal() {
      if (this.totalPayableAmt) {
        this.calculateGST();
      } else {
        console.log("totalPayableAmt not available");
      }
    },
    calculateGST() {
      let cgst = 0,
        sgst = 0,
        igst = 0;
      let totalPayableAfterGst = 0;
      this.sgstRate = 0;
      this.cgstRate = 0;
      this.igstRate = 0;
      // Check if the state is Kerala
      if (this.state.toLowerCase() === "kerala") {
        console.log("state kerala");
        cgst = this.gstPercentage / 2;
        sgst = this.gstPercentage / 2;

        // Calculate total amount after adding CGST and SGST
        totalPayableAfterGst =
          this.totalPayableAmt +
          (this.totalPayableAmt * cgst) / 100 +
          (this.totalPayableAmt * sgst) / 100;
        this.cgstRate = (this.totalPayableAmt * cgst) / 100; // CGST amount
        this.sgstRate = (this.totalPayableAmt * sgst) / 100; // SGST amount
        this.igstRate = 0; // IGST is not applicable in Kerala
      } else {
        console.log("state is out kerala");
        igst = this.gstPercentage;

        // Calculate total amount after adding IGST
        totalPayableAfterGst =
          this.totalPayableAmt + (this.totalPayableAmt * igst) / 100;
        this.igstRate = (this.totalPayableAmt * igst) / 100; // IGST amount
        this.cgstRate = 0; // CGST is not applicable
        this.sgstRate = 0; // SGST is not applicable
      }

      // Save the totalPayableAfterGst for later use
      this.totalPayableAfterGst = totalPayableAfterGst;
      this.gstRate = (this.totalPayableAmt * this.gstPercentage) / 100;
      console.log("gst=", this.gstPercentage);
      console.log("cgst=", cgst);
      console.log("sgst=", sgst);
      console.log("igst=", igst);
      console.log("totalPayableAfterGst=", this.totalPayableAfterGst);
    },

    // handleBillingModeOld() {
    //   // Handle the billingMode logic based on the selected mode
    //   console.log("billingMode=", this.billingMode);
    //   if (this.billingMode === "excludeGST") {
    //     // Including GST
    //     this.basePrice = this.totalPayableAfterGst; // Base price includes GST
    //     this.finalAmt = this.totalPayableAmt; // Final amount without GST
    //     this.hotelGst = this.totalPayableAfterGst - this.totalPayableAmt; // GST portion
    //   } else if (this.billingMode === "withoutGST") {
    //     // Without GST
    //     this.basePrice = this.totalPayableAmt; // Base price without GST
    //     this.finalAmt = this.totalPayableAmt; // Final amount without GST
    //     this.hotelGst = 0; // No GST applied
    //   } else if (this.billingMode === "includeGST") {
    //     // Excluding GST
    //     this.basePrice = this.totalPayableAmt; // Base price without GST
    //     this.finalAmt = this.totalPayableAfterGst; // Final amount with GST
    //     this.hotelGst = this.totalPayableAfterGst - this.totalPayableAmt; // GST portion
    //   }

    //   // Log the results for debugging
    //   console.log("Base Price:", this.basePrice);
    //   console.log("Final Amount:", this.finalAmt);
    //   console.log("Hotel GST:", this.hotelGst);
    // },
    handleBillingMode() {
      // Handle the billingMode logic based on the selected mode
      console.log("billingMode=", this.billingMode);
      if (this.billingMode === "includeGST") {
        // Including GST
        this.basePrice = this.totalPayableAmt - this.gstRate; // Base price includes GST
        this.finalAmt = this.totalPayableAmt; // Final amount without GST
        this.hotelGst = this.gstRate; // GST portion
      } else if (this.billingMode === "withoutGST") {
        // Without GST
        this.basePrice = this.totalPayableAmt; // Base price without GST
        this.finalAmt = this.totalPayableAmt; // Final amount without GST
        this.hotelGst = 0; // No GST applied
      } else if (this.billingMode === "excludeGST") {
        // Excluding GST
        this.basePrice = this.totalPayableAmt; // Base price without GST
        this.finalAmt = this.totalPayableAfterGst; // Final amount with GST
        this.hotelGst = this.totalPayableAfterGst - this.totalPayableAmt; // GST portion
      }

      // Log the results for debugging
      console.log("Base Price:", this.basePrice);
      console.log("Final Amount:", this.finalAmt);
      console.log("Hotel GST:", this.hotelGst);
    },
  },
};
</script>
  
<style>
.outlined-table {
  border: 1px solid grey;
  border-collapse: collapse;
}

.outlined-table th,
.outlined-table td {
  border: 1px solid grey;
  padding: 8px;
  text-align: left;
}

.outlined-table th {
  background-color: #f5f5f5;
}

.arrival-date {
  width: 120px !important;
}

.checkout-date {
  width: 120px !important;
}
</style>
  





