<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
 
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card class="pa-4">
          <v-layout wrap justify-end pt-4 v-if="reservationList">
            <v-flex xs2 align-self-center>
              <v-select
                    hide-details="auto"
                    v-model="bookingMode"
                    :items="['Direct', 'Company', 'Welkin', 'Agent', 'Online']"
                    :menu-props="{ maxHeight: '400' }"
                    outlined label="Booking Mode"
                    persistent-hint
                    dense clearable
                  ></v-select>
            </v-flex>
            <v-flex xs2 align-self-center>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    background-color="white"
                    outlined label="From Date"
                    color="#182444"
                    readonly 
                    
                    hide-details
                    clearable
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  :min="nowDate"
                  scrollable
                  @change="$refs.menu1.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2 align-self-center>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    background-color="white"
                    outlined label="To Date"
                    color="#182444"
                    readonly
                    
                    hide-details
                    clearable
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  :min="nowDate"
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2 align-self-center>
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                :return-value.sync="bookedDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="bookedDate"
                    background-color="white"
                    outlined label="Booked Date"
                    color="#182444"
                    readonly
                    
                    hide-details
                    clearable
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="bookedDate"
                  no-title
                  :min="nowDate"
                  scrollable
                  @change="$refs.menu3.save(bookedDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2 align-self-center>
              <v-text-field
                dense
                v-model="reservationNumber"
                outlined clearable
                 label="Reservation No."
                type="number"
                hide-spin-buttons
                hide-details="auto"
              >
              </v-text-field>
            </v-flex>
            <!-- <v-flex xs2 align-self-center>
              <v-text-field
                dense
                v-model="paymentStatus"
                outlined
                disabled
                type="number"
                hide-spin-buttons
                hide-details="auto"
              >
              </v-text-field>
            </v-flex> -->
          </v-layout>
          <v-layout wrap justify-space-around pt-4 v-if="reservationList">
            <v-flex xs12 px-0 px-sm-2 v-if="reservationList.length > 0">
              <v-card elevation="0" pa-1 outlined>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left tablefont">
                          <b>Reservation.No.</b>
                        </th>
                        <th class="text-left tablefont">
                          <b>Date Of Booking</b>
                        </th>
                        <th class="text-left tablefont">
                          <b>Guest Name</b>
                        </th>
                        <th class="text-left tablefont"><b>Booking Mode</b></th>
                        <th class="text-left tablefont">
                          <b>GST No.</b>
                        </th>
                        <th class="text-left tablefont">
                          <b>State</b>
                        </th>
                        <th class="text-left tablefont">
                          <b>Payment Mode</b>
                        </th>
                        <!-- <th class="text-left tablefont">
                          <b>Hotel GST</b>
                        </th> -->
                        <!-- <th class="text-left tablefont">
                          <b>Base Price</b>
                        </th> -->

                        <th class="text-left tablefont"><b>Total Amt.</b></th>
                        <th class="text-left tablefont"><b>View</b></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, i) in reservationList"
                        :key="i"
                        class="table"
                      >
                        <td>{{ value.reservationNumber }}</td>
                        <!-- <td>
                          <span v-if="page == 1">
                            {{ i + 1 }}
                          </span>
                          <span v-else>
                            {{ i + 1 + 20 * (page - 1) }}
                          </span>
                        </td> -->
                        <td>
                          <span v-if="value.bookingDate">
                            {{ formatDate(value.bookingDate) }}</span
                          >
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="value.guestName">{{
                            value.guestName
                          }}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="value.bookingMode">{{
                            value.bookingMode
                          }}</span>
                          <span v-else>-</span>
                        </td>

                        <td>
                          <div v-if="value.companyDetails">
                            <span v-if="value.companyDetails.companyGST">{{
                              value.companyDetails.companyGST
                            }}</span>
                            <span v-else>-</span>
                          </div>
                          <div v-else><span>-</span></div>
                        </td>
                        <td>
                          <div v-if="value.companyDetails">
                            <span v-if="value.companyDetails.state">{{
                              value.companyDetails.state
                            }}</span>
                            <span v-else>-</span>
                          </div>
                          <div v-else><span>-</span></div>
                        </td>
                        <td>
                          <span v-if="value.paymentMode">{{
                            value.paymentMode
                          }}</span>
                          <span v-else>-</span>
                        </td>
                        <!-- <td>
                          <span v-if="value.baseRate">{{
                            value.baseRate
                          }}</span>
                          <span v-else>-</span>
                        </td> -->
                        <td>
                          <span v-if="value.payableTotal">{{
                            value.payableTotal
                          }}</span>
                          <span v-else>-</span>
                        </td>

                        <td>
                          <v-icon
                            class="pr-2"
                            @click="
                              $router.push(
                                '/viewReservationDetail?id=' + value._id
                              )
                            "
                            >mdi-view-grid-plus</v-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-layout wrap v-if="reservationList">
                  <v-flex xs12 pt-4 v-if="reservationList.length > 0">
                    <v-pagination
                      small
                      color="#ff6200"
                      v-model="page"
                      :length="Pagelength"
                      :total-visible="7"
                    >
                    </v-pagination>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      page: 1,
      limit: 20,
      Pagelength: 0,
      dialog: false,
      reservationList: [],
      //filters
      menu1: false,
      menu2: false,
      menu3: false,
      nowDate: new Date().toISOString().slice(0, 10),
      bookedDate:"",
      fromDate:"",
      toDate:"",
      bookingMode:"",
      status:"",
      paymentStatus:"",
      reservationNumber:"",
      rules: {
        phone: [
          (v) => !!v || "Phone number is required",
          (v) => /^\d+$/.test(v) || "Phone number must be valid",
        ],
        email: [
          (v) => !!v || "Email is required",
          (v) => /.+@.+/.test(v) || "Email must be valid",
        ],
      },
    };
  },
  mounted() {
    this.getReservation();
  },
  watch: {
    page() {
      this.getReservation();
    },
    reservationNumber() {
      this.getReservation();
    },
    bookingMode() {
      this.getReservation();
    },
    fromDate() {
      this.getReservation();
    },
    toDate() {
      this.getReservation();
    },
    bookedDate() {
      this.getReservation();
    },
  },
  methods: {
    getReservation() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/reservation/list",
        data: {
          hotelId: this.$route.query.id,
          from: this.fromDate,
          to: this.toDate,
          bookingMode: this.bookingMode,
          bookedDate: this.bookedDate,
          // status: this.status,
          // paymentStatus: this.paymentStatus,
          reservationNumber: this.reservationNumber,
          page: this.page,
          limit: this.limit,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.reservationList = response.data.data;
          this.Pagelength = Math.ceil(response.data.count / this.limit);
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
  
<style>
.outlined-table {
  border: 1px solid grey;
  border-collapse: collapse;
}

.outlined-table th,
.outlined-table td {
  border: 1px solid grey;
  padding: 8px;
  text-align: left;
}

.outlined-table th {
  background-color: #f5f5f5;
}

.arrival-date {
  width: 120px !important;
}

.checkout-date {
  width: 120px !important;
}
</style>
  





