<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-space-around>
      <v-flex xs12>
        <v-tabs
          background-color="#002635"
          dark
          touchless
          grow
          ref="tabs"
          class="nsbold"
          style="font-size: 16px"
          v-model="activeTab"
        >
          <v-tab>Add Receipt</v-tab>
          <v-tab>View Receipt</v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab" touchless>
          <v-tab-item>
            <v-card outlined pa-2>
              <v-layout wrap justify-start px-4 py-4>
                <v-flex xs12 sm2 align-self-center>
                  <span class="title2">Reservation.No :</span>
                </v-flex>
                <v-flex xs12 sm2 align-self-center>
                  <v-text-field
                    dense
                    v-model="reservationNumber"
                    outlined
                    type="number"
                    hide-spin-buttons
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-layout
                wrap
                justify-center
                px-4
                pt-4
                v-if="foundReservation == true"
              >
                <v-flex xs12 sm6 md5 px-2 align-self-start>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm4 align-self-center pt-4>
                      <span class="title2">Total Amount :</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      align-self-center
                      pt-4
                      v-if="reserveArray.totalAmount"
                    >
                      ₹ {{ reserveArray.totalAmount }}
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center pt-4>
                      <span class="title2">Guest name :</span>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center pt-4 v-if="reserveArray">
                      <span class="title2" v-if="reserveArray.guestName">{{
                        reserveArray.guestName
                      }}</span>
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center pt-4>
                      <span class="title2">Address :</span>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center pt-4 v-if="reserveArray">
                      <span class="title2" v-if="reserveArray.companyDetails">{{
                        reserveArray.companyDetails.companyAddress
                      }}</span>
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center pt-4>
                      <span class="title2">Email :</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      align-self-center
                      pt-4
                      v-if="reserveArray.email"
                    >
                      <span
                        class="title2"
                        v-for="(item2, i) in reserveArray.email"
                        :key="i"
                        >{{ item2 }}
                        <span v-if="i < reserveArray.email.length - 1"
                          >,</span
                        ></span
                      >
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center pt-4>
                      <span class="title2">Phone :</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      align-self-center
                      pt-4
                      v-if="reserveArray.phone"
                    >
                      <span
                        class="title2"
                        v-for="(item2, i) in reserveArray.phone"
                        :key="i"
                        >{{ item2 }}
                        <span v-if="i < reserveArray.phone.length - 1"
                          >,</span
                        ></span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md5 px-2 align-self-start>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm4 align-self-center pt-4>
                      <span class="title2">Receipt Date :</span>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center pt-4>
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="receiptDates"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="receiptDates"
                            background-color="white"
                            outlined
                            color="#182444"
                            readonly
                            hide-details
                            clearable
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="receiptDates"
                          no-title
                          :min="nowDate"
                          scrollable
                          @change="$refs.menu1.save(receiptDates)"
                        >
                          <v-spacer></v-spacer>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center pt-4>
                      <span class="title2">Payment Details :</span>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center pt-4>
                      <v-select
                        hide-details="auto"
                        v-model="paymentDetails"
                        :items="[
                          'Agent',
                          'Cash',
                          'Online',
                          'Directly',
                          'Cheque',
                          'Credit','UPI ID','Bank Acc'
                        ]"
                        :menu-props="{ maxHeight: '400' }"
                        outlined
                        persistent-hint
                        dense
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center pt-4>
                      <span class="title2">Payment Type :</span>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center pt-4>
                      <v-select
                        hide-details="auto"
                        v-model="paymentType"
                        :items="['Advance', 'Full']"
                        :menu-props="{ maxHeight: '400' }"
                        outlined
                        persistent-hint
                        dense
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center pt-4>
                      <span class="title2">Remarks :</span>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center pt-4>
                      <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="remaks"
                        hide-details="auto"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout
                wrap
                justify-center
                px-4
                pb-4
                v-if="foundReservation == true"
              >
                <v-flex xs12 sm6 px-4 align-self-start>
                  <v-layout wrap justify-center>
                    <v-flex xs4 align-self-center pt-4>
                      <span class="title2">Advance Payment :</span>
                    </v-flex>
                    <v-flex xs4 align-self-center pt-4>
                      <!-- <v-text-field
                        dense v-model="advanecPayment"
                        outlined
                        @change="calBalance()"
                        type="number"
                        hide-spin-buttons
                        hide-details="auto"
                      >
                      </v-text-field> -->
                      <v-text-field
                        dense
                        v-model="advanecPayment"
                        outlined
                        type="number"
                        hide-spin-buttons
                        hide-details="auto"
                      >
                      </v-text-field>
                    </v-flex>
                    <!-- <v-flex xs4 align-self-center pt-4>
                      <v-btn class="mx-2" dark color="green">Paid</v-btn>
                    </v-flex> -->
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 px-4 align-self-start>
                  <v-layout wrap justify-center>
                    <v-flex xs5 align-self-center pt-4>
                      <span class="title2">Balance Payment :</span>
                    </v-flex>
                    <v-flex xs5 align-self-center pt-4>
                      <v-text-field
                        dense
                        outlined
                        disabled
                        v-model="balancePayment"
                        type="number"
                        hide-spin-buttons
                        hide-details="auto"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs5 align-self-center pt-4>
                      <v-btn
                        color="#f17343"
                        dark
                        block
                        @click="add()"
                        class="btnstly"
                        style="cursor: pointer"
                        ><span
                          style="font-family: LexendFont; text-transform: none"
                          >Save</span
                        ></v-btn
                      >
                    </v-flex>
                    <!-- <v-flex xs4 align-self-center pt-4>
                      <v-btn class="mx-2" dark color="orange">Pending</v-btn>
                    </v-flex> -->
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card outlined pa-2>
              <v-layout wrap justify-start px-4 py-4>
                <v-flex xs12 sm2 align-self-center>
                  <span class="title2">Reservation.No :</span>
                </v-flex>
                <v-flex xs12 sm2 align-self-center>
                  <v-text-field
                    dense
                    v-model="searchNumber"
                    outlined
                    type="number"
                    hide-spin-buttons
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-flex>
                <v-spacer class="hidden-sm-and-down"></v-spacer>
      <v-flex xs12 sm3 align-self-center pb-1 class="text-right"   v-if="foundReservation2 == true">
        <v-btn
          @click="generateReceipt()"
          
          color="#ff6200" dark
          >Download Receipt</v-btn
        >
      </v-flex>
              </v-layout>
              <v-layout
                wrap
                justify-center
                px-4
                py-4
                v-if="foundReservation2 == true"
              >
                <v-flex xs12 sm6 md5 px-2 align-self-start>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm4 align-self-center class="text-left" pt-4>
                      <span class="title2">Total Amount :</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      align-self-center
                      class="text-left"
                      pt-4
                      v-if="reserveArray2.totalAmount"
                    >
                      ₹ {{ reserveArray2.totalAmount }}
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center class="text-left" pt-4>
                      <span class="title2">Guest name :</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      align-self-center
                      class="text-left"
                      pt-4
                      v-if="reserveArray2"
                    >
                      <span class="title2" v-if="reserveArray2.guestName">{{
                        reserveArray2.guestName
                      }}</span>
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center class="text-left" pt-4>
                      <span class="title2">Address :</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      align-self-center
                      class="text-left"
                      pt-4
                      v-if="reserveArray2"
                    >
                      <span
                        class="title2"
                        v-if="reserveArray2.companyDetails"
                        >{{ reserveArray2.companyDetails.companyAddress }}</span
                      >
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center class="text-left" pt-4>
                      <span class="title2">Email :</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      align-self-center
                      class="text-left"
                      pt-4
                      v-if="reserveArray2.email"
                    >
                      <span
                        class="title2"
                        v-for="(item2, i) in reserveArray2.email"
                        :key="i"
                        >{{ item2 }}
                        <span v-if="i < reserveArray2.email.length - 1"
                          >,</span
                        ></span
                      >
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center class="text-left" pt-4>
                      <span class="title2">Phone :</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      align-self-center
                      class="text-left"
                      pt-4
                      v-if="reserveArray2.phone"
                    >
                      <span
                        class="title2"
                        v-for="(item2, i) in reserveArray2.phone"
                        :key="i"
                        >{{ item2 }}
                        <span v-if="i < reserveArray2.phone.length - 1"
                          >,</span
                        ></span
                      >
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center class="text-left" pt-4>
                      <span class="title2">Advance Payment :</span>
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center class="text-left" pt-4>
                      <span
                        class="title2"
                        v-if="reserveArray2.advancePayment"
                        >{{ reserveArray2.advancePayment }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md5 px-2 align-self-start>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm4 align-self-center class="text-left" pt-4>
                      <span class="title2">Receipt Date :</span>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center class="text-left" pt-4>
                      <span class="title2" v-if="reserveArray2.reciptDate">{{
                        formatDate(reserveArray2.reciptDate)
                      }}</span>
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center class="text-left" pt-4>
                      <span class="title2">Payment Details :</span>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center class="text-left" pt-4>
                      <span
                        class="title2"
                        v-if="reserveArray2.advancePaymentType"
                        >{{ reserveArray2.advancePaymentType }}</span
                      >
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center class="text-left" pt-4>
                      <span class="title2">Payment Type :</span>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center class="text-left" pt-4>
                      <span
                        class="title2"
                        v-if="reserveArray2.advancePaymentDetails"
                        >{{ reserveArray2.advancePaymentDetails }}</span
                      >
                    </v-flex>
                    <v-flex xs12 sm4 align-self-center class="text-left" pt-4>
                      <span class="title2">Remarks :</span>
                    </v-flex>
                    <v-flex xs12 sm6 align-self-center class="text-left" pt-4>
                      <span class="title2" v-if="reserveArray2.remarks">{{
                        reserveArray2.remarks
                      }}</span>
                    </v-flex>
                    <v-flex xs12 sm5 align-self-center class="text-left" pt-4>
                      <span class="title2">Balance Payment :</span>
                    </v-flex>
                    <v-flex xs12 sm5 align-self-center class="text-left" pt-4>
                      <span
                        class="title2"
                        v-if="reserveArray2.balancePayment"
                        >{{ reserveArray2.balancePayment }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      activeTab: 0,
      balancePayment: 0,
      advanecPayment: 0,
      total: 0,
      reservationNumber: "",
      paymentDetails: "",
      paymentType: "",
      receiptDates: "",
      remaks: "",
      menu1: false,
      nowDate: new Date().toISOString().slice(0, 10),
      foundReservation: false,
      reserveArray: {},
      searchNumber: "",
      foundReservation2: false,
      reserveArray2: {},
      rules: {
        phone: [
          (v) => !!v || "Phone number is required",
          (v) => /^\d+$/.test(v) || "Phone number must be valid",
        ],
        email: [
          (v) => !!v || "Email is required",
          (v) => /.+@.+/.test(v) || "Email must be valid",
        ],
      },
    };
  },
  // mounted() {
  //   this.getReservationNo();
  // },
  watch: {
    reservationNumber() {
      this.getReservationList();
    },
    searchNumber() {
      this.searchReceipt();
    },
    advanecPayment() {
      this.calBalance();
    },
  },

  methods: {
    calBalance() {
      this.balancePayment = this.total - this.advanecPayment;
    },
    searchReceipt() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/booking/list",
        data: {
          hotelId: this.$route.query.id,
          reservationNumber: this.searchNumber,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          let testArr2 = response.data.data;
          if (testArr2) {
            if (testArr2.length > 0) {
              this.foundReservation2 = true;
              this.reserveArray2 = response.data.data[0];
              this.total = this.reserveArray2.totalAmount;
              console.log("reserveArray2=", this.reserveArray2);
            } else {
              this.foundReservation2 = false;
            }
          }
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    generateReceipt() {
  this.appLoading = true;

  axios({
    method: "get",
    url: "/get/recipt",
    responseType: "blob", // Ensure the response is handled as a blob
    params: {
      bookingId: this.reserveArray._id,
    },
    headers: {
      token: localStorage.getItem("token"),
    },
  })
  .then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "receipt.pdf"); // Set the default file name
    document.body.appendChild(link);
    link.click(); // Programmatically click the link to trigger the download
    document.body.removeChild(link); // Clean up the link element
  })
  .catch((error) => {
    console.error("Error downloading the PDF:", error);
  })
  .finally(() => {
    this.appLoading = false;
  });
},
    getReservationList() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/reservation/list",
        data: {
          hotelId: this.$route.query.id,
          reservationNumber: this.reservationNumber,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          let testArr = response.data.data;
          if (testArr) {
            if (testArr.length > 0) {
              this.foundReservation = true;
              this.reserveArray = response.data.data[0];
              this.total = this.reserveArray.totalAmount;
              console.log("reserveArray=", this.reserveArray);
            } else {
              this.foundReservation = false;
            }
          }
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },

    add() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/reservation/add/advance",
        data: {
          reciptDate: this.receiptDates,
          advancePaymentDetails: this.paymentDetails,
          advancePaymentType: this.paymentType,
          remarks: this.remaks,
          advancePayment: this.advanecPayment,
          bookingId: this.reserveArray._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          window.location.reload();
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
  
<style>
.outlined-table {
  border: 1px solid grey;
  border-collapse: collapse;
}

.outlined-table th,
.outlined-table td {
  border: 1px solid grey;
  padding: 8px;
  text-align: left;
}

.outlined-table th {
  background-color: #f5f5f5;
}

.arrival-date {
  width: 120px !important;
}

.checkout-date {
  width: 120px !important;
}
</style>
  





